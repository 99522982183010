
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default {
  name: "OtMeasuresTE",
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    bl: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement"],

  setup(props) {
    const store = useStore();

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: update"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const equipmentsTableData = !props.bl
      ? computed(() => store.getters.Equipments)
      : computed(() => store.getters.EquipmentsBL);
    const measureTypeData = computed(() => store.getters.MeasureType);
    const measureUnitData = computed(() => store.getters.MeasureUnit);
    const tableData = computed(() => store.getters.MeasuresOT);
    const EquipmentList = computed(() => store.getters.EquipmentList);
    const measureType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const createMeasure = () => {
      const payload = {
        equipment_id: null,
        measure_type_id: null,
        measure_unit_id: null,
        measure: "",
        remarks: "",
        new_item: true,
      };
      store.commit("setMeasureOT", payload);
    };
    const removeElement = (i) => {
      tableData.value.splice(i, 1);
    };
    const measureUnit = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headers = ref([
      { label: "iequipment", prop: "equipment_id" },
      { label: "imeasuretype", prop: "measure_type_id" },
      { label: "imeasureunit", prop: "measure_unit_id" },
      { label: "imeasure", prop: "measure" },
      { label: "iremarks", prop: "remarks" },
    ]);
    const headerTable = ref(headers.value);

    const updateMeasureUnit = (item) => {
      const options = measureTypeData.value.find((x) => x.id === item);
      measureUnit.value.options = options.measure_unit_type.measure_unit;
    };

    const getMeasureType = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/measure-types/?per_page=100").then(({ data }) => {
        store.commit("setMeasureType", data);
        measureType.value.list = data;
        measureType.value.options = data;
      });
    };
    const selectMeasureType = (query) => {
      if (query !== "") {
        measureType.value.loading = true;
        setTimeout(() => {
          measureType.value.loading = false;
          measureType.value.options = measureType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/measure-types/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.measure_types.forEach((item) => {
                store.commit("addMeasureType", item);
              });
              measureType.value.list = measureTypeData.value;
              measureType.value.options = data.measure_types;
            });
          }
        }, 200);
      } else {
        measureType.value.options = measureTypeData.value;
      }
    };

    const getMeasureUnit = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/measure-units/?per_page=100").then(
        ({ data }) => {
        store.commit("setMeasureUnit", data);
        measureUnit.value.list = data.measure_units;
        measureUnit.value.options = data.measure_units;
      });
    };
    const selectMeasureUnit = (query) => {
      if (query !== "") {
        measureUnit.value.loading = true;
        setTimeout(() => {
          measureUnit.value.loading = false;
          measureUnit.value.options = measureUnit.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureUnit.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/measure-units/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.measure_units.forEach((item) => {
                store.commit("addMeasureUnit", item);
              });
              measureUnit.value.list = measureUnitData.value;
              measureUnit.value.options = data.measure_units;
            });
          }
        }, 200);
      } else {
        measureUnit.value.options = measureUnitData.value;
      }
    };

    onMounted(() => {
      getMeasureType();
      getMeasureUnit();
    });

    return {
      canCreate,
      canEdit,
      EquipmentList,
      tableData,
      activeNames,
      headers,
      headerTable,
      measureType,
      measureUnit,
      equipmentsTableData,
      updateMeasureUnit,
      selectMeasureType,
      selectMeasureUnit,
      createMeasure,
      removeElement,
    };
  },
};

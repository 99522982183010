import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = {
  class: "filter-card mb-6",
  style: {"padding-top":"0 !important"}
}
const _hoisted_3 = {
  class: "d-sm-flex flex-row justify-content-between mb-6",
  style: {"margin-top":"10px"}
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "row g-4" }
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "col-md-12 mt-3" }
const _hoisted_8 = { class: "col-md-12 mt-3" }
const _hoisted_9 = { class: "col-3" }
const _hoisted_10 = { class: "required" }
const _hoisted_11 = { class: "required" }
const _hoisted_12 = { class: "col-3" }
const _hoisted_13 = { class: "required" }
const _hoisted_14 = { class: "required" }
const _hoisted_15 = { class: "col-3" }
const _hoisted_16 = { class: "required" }
const _hoisted_17 = { class: "required" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_TransportOrderTypeSelect = _resolveComponent("TransportOrderTypeSelect")!
  const _component_TransportOrderStatusSelect = _resolveComponent("TransportOrderStatusSelect")!
  const _component_OtItinerariesList = _resolveComponent("OtItinerariesList")!
  const _component_OtParticipantList = _resolveComponent("OtParticipantList")!
  const _component_OtToEquipmentTE = _resolveComponent("OtToEquipmentTE")!
  const _component_OtMeasuresTE = _resolveComponent("OtMeasuresTE")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_menu, {
        "default-active": 1,
        class: "el-menu-demo",
        style: {},
        mode: "horizontal",
        onSelect: _ctx.handleSelect
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_sub_menu, { index: "1" }, {
            title: _withCtx(() => [
              _createTextVNode("Generar")
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                index: "2-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.generate('A')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Admitase/Entregese")
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, {
                index: "2-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.generate('E')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Orden de Transporte")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_sub_menu, { index: "2" }, {
            title: _withCtx(() => [
              _createTextVNode("Enviar")
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                index: "2-1",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onclickImport(0)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Documento")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSelect"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("tiOrderTransport")), 1),
        _createElementVNode("div", null, [
          (_ctx.ediFiles.length > 0)
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                modelValue: _ctx.edi_file_model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.edi_file_model) = $event)),
                name: "orderType",
                class: "custom-select p-0",
                placeholder: `${_ctx.$t('iselect')}`,
                "reserve-keyword": "",
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeEdiSelect($event))),
                style: {"margin-right":"10px","margin-top":"3px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ediFiles, (edi_file) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: edi_file.id,
                      label: `${edi_file.message_type}`,
                      value: edi_file.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(`${edi_file.message_type} ${edi_file.type}`), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder"]))
            : _createCommentVNode("", true),
          (_ctx.ediFiles.length > 0)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "custom-btn me-2",
                href: _ctx.full_edi_download_path,
                target: "_blank",
                download: "edifile.edi"
              }, _toDisplayString(_ctx.$t("btndownload")) + " EDI ", 9, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "custom-btn me-2",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.saveElement && _ctx.saveElement(...args)))
          }, _toDisplayString(_ctx.$t("btnSave")), 1),
          _createElementVNode("button", {
            class: "cancel-btn",
            onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancelElement && _ctx.cancelElement(...args)), ["prevent","stop"]))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("btnCancel")), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("iDescription")), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ot.description) = $event)),
              type: "text",
              name: "ot_desc",
              autocomplete: "off",
              class: "w-100"
            }, null, 512), [
              [_vModelText, _ctx.ot.description]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("iobservation")), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ot.observations) = $event)),
              type: "text",
              name: "ot_desc",
              autocomplete: "off",
              class: "w-100"
            }, null, 512), [
              [_vModelText, _ctx.ot.observations]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("iTransportOrderType")), 1),
          _createVNode(_component_TransportOrderTypeSelect, {
            transport_order_type_id: _ctx.ot.type_id,
            "onUpdate:transport_order_type_id": _cache[9] || (_cache[9] = ($event: any) => (_ctx.ot.type_id = $event))
          }, null, 8, ["transport_order_type_id"]),
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("iReceptionStatus")), 1),
          _createVNode(_component_el_select, {
            modelValue: _ctx.ot.reception_status,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.ot.reception_status) = $event)),
            name: "orderType",
            class: "custom-select w-100 p-0",
            clearable: "",
            placeholder: `${_ctx.$t('iselect')}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: _ctx.$t('iis_full'),
                value: "F"
              }, null, 8, ["label"]),
              _createVNode(_component_el_option, {
                label: _ctx.$t('iis_empty'),
                value: "E"
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("iTransportOrderStatus")), 1),
          _createVNode(_component_TransportOrderStatusSelect, {
            transport_order_status_id: _ctx.ot.status,
            "onUpdate:transport_order_status_id": _cache[11] || (_cache[11] = ($event: any) => (_ctx.ot.status = $event))
          }, null, 8, ["transport_order_status_id"]),
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("iAdmissionStatus")), 1),
          _createVNode(_component_el_select, {
            size: "small",
            modelValue: _ctx.ot.admission_status,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.ot.admission_status) = $event)),
            name: "orderType",
            class: "custom-select w-100 p-0",
            clearable: "",
            placeholder: `${_ctx.$t('iselect')}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: _ctx.$t('iis_full'),
                value: "F"
              }, null, 8, ["label"]),
              _createVNode(_component_el_option, {
                label: _ctx.$t('iis_empty'),
                value: "E"
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("iTransportType")), 1),
          _createVNode(_component_el_select, {
            size: "small",
            modelValue: _ctx.ot.transportation_type,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.ot.transportation_type) = $event)),
            name: "orderType",
            class: "custom-select w-100 p-0",
            clearable: "",
            placeholder: `${_ctx.$t('iselect')}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "Propio",
                value: "P"
              }),
              _createVNode(_component_el_option, {
                label: "Cedido",
                value: "C"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"]),
          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("iTransportMode")), 1),
          _createVNode(_component_el_select, {
            size: "small",
            modelValue: _ctx.ot.transportation_mode,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.ot.transportation_mode) = $event)),
            name: "orderType",
            class: "custom-select w-100 p-0",
            clearable: "",
            placeholder: `${_ctx.$t('iselect')}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "Bookings",
                value: "B"
              }),
              _createVNode(_component_el_option, {
                label: "Traslados desde Booking",
                value: "TB"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_OtItinerariesList, {
            onCreateElement: _ctx.createOtItinerariesList,
            onRemoveElement: _ctx.removeOtItinerariesList
          }, null, 8, ["onCreateElement", "onRemoveElement"])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_OtParticipantList, {
            onCreateElement: _ctx.createOtParticipantList,
            onRemoveElement: _ctx.removeOtParticipantList
          }, null, 8, ["onCreateElement", "onRemoveElement"])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_OtToEquipmentTE)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_OtMeasuresTE)
        ])
      ])
    ])
  ]))
}

import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "OtToEquipmentTE",
  props: {
    tableData: {
      type: Array,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    disableOT: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["createElement", "removeElement"],

  setup() {
    const store = useStore();
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: create"
        ) !== -1
      );
    });
    const canEdit = computed(() => {
      return (
        permissions.value.findIndex(
          (x) => x.name === "transport order: update"
        ) !== -1
      );
    });

    const activeNames = ref(["1"]);
    const goodTableData = computed(() => store.getters.Goods);
    const equipmentTypeData = computed(() => store.getters.RegisterEquipments);
    const equipmentData = computed(() => store.getters.EquipmentsMF);
    const EquipmentList = computed(() => store.getters.EquipmentList);
    const EquipmentListNoCheck = computed(
      () => store.getters.EquipmentListNoCheck
    );
    const MeasuresOTNoCheck = computed(() => store.getters.MeasuresOTNoCheck);
    const equipmentType = ref({
      loading: false,
      options: [],
      list: [],
    });
    const equipments = ref({
      loading: false,
      options: [],
      list: [],
    });
    const headers = ref([
      { label: "ishipload_id", prop: "shipload_id" },
      { label: "ito_special_condition_id", prop: "to_special_condition_id" },
      { label: "iobservations", prop: "observations" },
      // { label: "iregistered_equipment_id", prop: "registered_equipment_id" },
      // { label: "iequipment_id", prop: "equipment_id" },
      { label: "iregistration_number", prop: "registration_number" },
      { label: "iequipment_type", prop: "equipment_type" },
      { label: "iequipment_size", prop: "equipment_size" },
      { label: "iiso", prop: "iso" },
      { label: "itare", prop: "tare" },
      { label: "iconnected_onboard", prop: "connected_onboard" },
      { label: "iconnected_in_station", prop: "connected_in_station" },
      { label: "iconnected_transport", prop: "connected_transport" },
      { label: "irelative_humidity", prop: "relative_humidity" },
      { label: "icontain_origin", prop: "contain_origin" },
      { label: "icontain_destination", prop: "contain_destination" },
      { label: "iorigin_service", prop: "origin_service" },
      { label: "idestination_service", prop: "destination_service" },
      { label: "iis_shipper_owner", prop: "is_shipper_owner" },
      { label: "iseal_number", prop: "seal_number" },
      { label: "iallowed_by_pif", prop: "allowed_by_pif" },
      { label: "iallowed_by_customs", prop: "allowed_by_customs" },
      { label: "iweighers", prop: "weighers" },
      { label: "iis_empty", prop: "is_empty" },
    ]);
    const headerTable = ref(headers.value);
    const specialConditionTableData = ref([]);
    const getSpecialConditionTableData = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/to_special_condition/lists?per_page=100").then(
        function (response) {
          specialConditionTableData.value = response.data.to_special_condition;
          store.commit("setLoadingStatus", false);
        }
      );
    };
    const getEquipmentType = () => {
      if (!equipmentTypeData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/registered-equipment").then(({ data }) => {
          store.commit("setRegisterEquipment", data);
          equipmentType.value.list = data;
          equipmentType.value.options = data;
        });
      } else {
        equipmentType.value.list = equipmentTypeData.value;
        equipmentType.value.options = equipmentTypeData.value;
      }
    };
    const selectEquipmentType = (query) => {
      if (query !== "") {
        equipmentType.value.loading = true;
        setTimeout(() => {
          equipmentType.value.loading = false;
          equipmentType.value.options = equipmentType.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.registration_number
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
          if (!equipmentType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/registered-equipment/lists`, {
              params: { per_page: 25, registration_number: query },
            }).then(({ data }) => {
              data.registered_equipment.forEach((element) => {
                store.commit("addEquipmentType", element);
              });
              equipmentType.value.list = equipmentTypeData.value;
              equipmentType.value.options = data.registered_equipment;
            });
          }
        }, 200);
      } else {
        equipmentType.value.options = equipmentTypeData.value;
      }
    };

    const getEquipments = () => {
      if (!equipmentData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/equipments/lists").then(({ data }) => {
          store.commit("setEquipment", data.equipments);
          equipments.value.list = data.equipments;
          equipments.value.options = data.equipments;
        });
      } else {
        equipments.value.list = equipmentData.value;
        equipments.value.options = equipmentData.value;
      }
    };
    const removeElement = (i) => {
      EquipmentList.value.splice(i, 1);
    };
    const selectEquipment = (query) => {
      if (query !== "") {
        equipments.value.loading = true;
        setTimeout(() => {
          equipments.value.loading = false;
          equipments.value.options = equipments.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!equipments.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/equipments/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.equipments.forEach((element) => {
                store.commit("addEquipment", element);
              });
              equipments.value.list = equipmentData.value;
              equipments.value.options = data.equipments;
            });
          }
        }, 200);
      } else {
        equipments.value.options = equipmentData.value;
      }
    };

    onMounted(() => {
      getEquipmentType();
      getEquipments();
      getSpecialConditionTableData();
    });

    return {
      canCreate,
      canEdit,
      specialConditionTableData,
      EquipmentList,
      EquipmentListNoCheck,
      MeasuresOTNoCheck,
      activeNames,
      headers,
      headerTable,
      equipmentType,
      equipments,
      goodTableData,
      selectEquipmentType,
      selectEquipment,
      removeElement,
    };
  },
});


import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import OtMeasuresTE from "@/components/maritime-file/tables-editable/OtMeasuresTE.vue";
import OtItinerariesList from "@/components/maritime-file/tables-editable/OtItinerariesList.vue";
import OtParticipantList from "@/components/maritime-file/tables-editable/OtParticipantList.vue";
import OtToEquipmentTE from "@/components/maritime-file/tables-editable/OtToEquipmentTE.vue";
import TransportOrderTypeSelect from "@/components/catalogs-select/TransportOrderTypeSelect.vue";
import TransportOrderStatusSelect from "@/components/catalogs-select/TransportOrderStatusSelect.vue";
import store from "@/store";

export default defineComponent({
  name: "TransportOrdersForm",
  props: {
    otId: {
      type: String,
    },
  },
  components: {
    OtMeasuresTE,
    OtItinerariesList,
    OtParticipantList,
    OtToEquipmentTE,
    TransportOrderTypeSelect,
    TransportOrderStatusSelect,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const ot = ref([]);
    const ediFiles = ref([]) as any;
    const edi_file_model = ref(0);
    const full_edi_download_path = ref("");
    const api_url = process.env.VUE_APP_API_URL + "/storage/";
    const ParticipantList = computed(() => store.getters.ParticipantList);
    const ItinerariesList = computed(() => store.getters.ItinerariesList);
    const EquipmentList = computed(() => store.getters.EquipmentList);
    const MeasuresOT = computed(() => store.getters.MeasuresOT);
    const getTransportOrder = () => {
      ApiService.query(`/api/transportation_orders/` + props.otId, {}).then(
        ({ data }) => {
          ot.value = data;
          store.commit("setActionMode", true);
          store.commit("clearMeasureOT");
          ediFiles.value = data.edi_paths;
          if (ediFiles.value.length > 0) {
            changeEdiSelect(ediFiles.value[0].id);
            edi_file_model.value = ediFiles.value[0].id;
          }

          data.to_shiploads.forEach(function (eqVal: any) {
            eqVal.shipload.goods_measures.forEach(function (meVal) {
              const payload = {
                equipment_id:
                  eqVal.shipload.equipment_involved.registered_equipment
                    .registration_number,
                measure_type_id: meVal.measure_type_id,
                measure_unit_id: meVal.measure_unit_id,
                measure: meVal.measure,
                remarks: meVal.remarks,
                new_item: meVal.new_item,
              };
              store.commit("setMeasureOT", payload);
            });
            store.commit("addEquipmentList", {
              transportation_order_id: data.id,
              shipload_id: eqVal.shipload_id,
              to_special_condition_id: eqVal.to_special_condition_id,
              registered_equipment_id:
                eqVal.shipload.equipment_involved.registered_equipment_id,
              equipment_id: eqVal.shipload.equipment_involved.equipment_id,
              registration_number:
                eqVal.shipload.equipment_involved.registered_equipment
                  .registration_number,
              equipment_type:
                eqVal.shipload.equipment_involved.equipment
                  .equipment_subtype_id,
              equipment_size: eqVal.shipload.equipment_involved.equipment.size,
              tare: eqVal.shipload.equipment_involved.registered_equipment.tare,
              iso: eqVal.shipload.equipment_involved.registered_equipment
                .international_code,
              connected_onboard: undefined,
              connected_in_station: undefined,
              connected_transport: undefined,
              relative_humidity: "",
              contain_origin: "",
              contain_destination: "",
              origin_service: "",
              destination_service: "",
              is_shipper_owner: undefined,
              seal_number: eqVal.shipload.equipment_involved.seal_number,
              observations: "",
              allowed_by_pif: undefined,
              weighers: undefined,
              is_empty: undefined,
              to_special_condition: undefined,
              registered_equipment: undefined,
            });
          });
          // store.commit("setEquipmentList", dataContent.value.to_shiploads);
          store.commit("setParticipantList", data.to_participants);
          store.commit("setActionMode", false);
          store.commit("setOtItinerariesList", data.value.to_itineraries);
        }
      );
    };

    onMounted(() => {
      getTransportOrder();
    });

    const createOtParticipantList = () => {
      store.commit("createParticipantList");
    };
    const removeOtParticipantList = (i) => {
      ParticipantList.value.splice(i, 1);
    };
    const createOtItinerariesList = () => {
      store.commit("createOtItinerariesList");
    };
    const removeOtItinerariesList = (i) => {
      ItinerariesList.value.splice(i, 1);
    };
    const generate = (mode) => {
      if (mode === "A") {
        ApiService.post(
          "/api/transportation_orders/" + props.otId + "/generate",
          {
            type: "coparn",
          }
        );
      } else {
        ApiService.post(
          "/api/transportation_orders/" + props.otId + "/generate",
          {
            type: "iftmin",
          }
        );
      }
    };
    const onclickImport = (value) => {
      let payload = {
        show: true,
        option: value,
        data: [] as any,
      };
      store.commit("setImportFileTODialog", payload);
    };
    const changeEdiSelect = (id: any) => {
      full_edi_download_path.value =
        api_url + ediFiles.value.find((a: any) => a.id === id).path;
    };
    const cancelElement = () => {
      store.commit("setActionMode", true);
      store.commit("clearEquipmentList");
      store.commit("clearOtItinerariesList");
      store.commit("clearParticipantList");
      store.commit("clearMeasureOT");
      emit("back", { form: false });
    };
    const saveElement = () => {
      const equipOt = [] as any;
      EquipmentList.value.forEach(function (eqVal, eqKey) {
        const toMeasures = [] as any;
        MeasuresOT.value.forEach(function (meVal, meKey) {
          if (meVal.equipment_id === eqVal.registration_number) {
            toMeasures.push({
              measure_type_id: meVal.measure_type_id,
              measure_unit_id: meVal.measure_unit_id,
              measure: meVal.measure,
              remarks: meVal.remarks,
            });
          }
        });
        equipOt.push({
          shipload_id: eqVal.shipload_id,
          registered_equipment_id: eqVal.registered_equipment_id,
          equipment_id: eqVal.equipment_id,
          to_special_condition_id: eqVal.to_special_condition_id,
          registration_number: eqVal.registration_number,
          equipment_type: eqVal.equipment_type,
          equipment_size: eqVal.equipment_size,
          iso: eqVal.iso,
          tare: eqVal.tare,
          connected_onboard: eqVal.connected_onboard,
          connected_in_station: eqVal.connected_in_station,
          connected_transport: eqVal.connected_transport,
          relative_humidity: eqVal.relative_humidity,
          contain_origin: eqVal.contain_origin,
          contain_destination: eqVal.contain_destination,
          origin_service: eqVal.origin_service,
          destination_service: eqVal.destination_service,
          is_shipper_owner: eqVal.is_shipper_owner,
          seal_number: eqVal.seal_number,
          observations: eqVal.observations,
          allowed_by_pif: eqVal.allowed_by_pif,
          weighers: eqVal.weighers,
          is_empty: eqVal.is_empty,
          to_measures: toMeasures,
        });
      });
      const data = {
        booking_id: ot.value["booking_id"],
        bl_id: ot.value["bl_id"],
        description: ot.value["description"],
        type_id: ot.value["type_id"],
        status: ot.value["status"],
        transportation_type: ot.value["transportation_type"],
        transportation_mode: ot.value["transportation_mode"],
        reception_status: ot.value["reception_status"],
        admission_status: ot.value["admission_status"],
        observations: ot.value["observations"],
        to_shiploads: equipOt,
        to_participants: ParticipantList.value,
        to_itineraries: ItinerariesList.value,
      };
      ApiService.put("/api/transportation_orders/" + ot.value["id"], data).then(
        function () {
          store.commit("setActionMode", true);
          emit("back", { form: false });
        }
      );
    };

    return {
      ot,
      ediFiles,
      edi_file_model,
      full_edi_download_path,
      api_url,
      createOtParticipantList,
      removeOtParticipantList,
      createOtItinerariesList,
      removeOtItinerariesList,
      getTransportOrder,
      generate,
      onclickImport,
      changeEdiSelect,
      cancelElement,
      saveElement,
    };
  },
});


import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "OtMfSelect",
  props: {
    bl_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:mf_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const mfData = computed(() => store.getters.MaritimeFiles);
    const element_id = ref("");
    const inputMF = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isBLRequired = (value) => {
      if (props.required && !value) {
        return t("rbl");
      }
      return true;
    };

    const { errorMessage, value } = useField("bl", isBLRequired);

    const getMf = (query) => {
      store.commit("setLoading", false);
      ApiService.query("/api/maritimefiles", {
        params: {
          per_page: 10,
          code: query,
        },
      }).then(({ data }) => {
        //store.commit("setBLs", data.bookings);
        inputMF.value.list = data.maritime_files;
        inputMF.value.options = data.maritime_files;
      });
    };
    const selectBooking = (query) => {
      if (query !== "" || query !== null) {
        inputMF.value.loading = true;
        setTimeout(() => {
          inputMF.value.loading = false;
          inputMF.value.options = inputMF.value.list.filter(
            (item: Record<string, any>) => {
              return (
                item.id.toString().toLowerCase().indexOf(query.toLowerCase()) >
                  -1 ||
                item.code
                  .toString()
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) > -1
              );
            }
          );
        }, 200);
      } else {
        inputMF.value.options = mfData;
      }
    };

    watch(
      () => props.bl_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getMf("");
      }, 3000);
    });

    return {
      element_id,
      inputMF,
      errorMessage,
      selectBooking,
      getMf,
    };
  },
};


import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import CardBasicNew from "@/components/cards/CardBasicNew.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import OtBillLadingSelect from "@/components/catalogs-select/OtBillLadingSelect.vue";
import OtBookingSelect from "@/components/catalogs-select/OtBookingSelect.vue";
import TransportOrdersForm from "@/views/maritime_file/TransportOrdersForm.vue";
import router from "@/router";
import { SPSelect } from "@/components/catalogs-select";
import TransportOrderTypeSelect from "@/components/catalogs-select/TransportOrderTypeSelect.vue";
import TransportOrderStatusSelect from "@/components/catalogs-select/TransportOrderStatusSelect.vue";
import OtMfSelect from "@/components/catalogs-select/OtMfSelect.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  last_page: number;
}

export default defineComponent({
  name: "TransportOrders",
  components: {
    OtMfSelect,
    TransportOrderStatusSelect,
    TransportOrderTypeSelect,
    SPSelect,
    TransportOrdersForm,
    OtBookingSelect,
    OtBillLadingSelect,
    CardBasicNew,
    TableBasicSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const search = ref("");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };
    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "transport order: create")
            .length > 0
        );
      }
      return false;
    });
    const canSeeDetails = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "transport order: show")
            .length > 0
        );
      }
      return false;
    });
    const canEdit = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "transport order: update")
            .length > 0
        );
      }
      return false;
    });
    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "transport order: delete")
            .length > 0
        );
      }
      return false;
    });

    const totalFiles = computed(() => store.getters.TotalFiles);
    const dataList = ref([]);
    const filtered = ref([]);
    const viewType = ref(user.value.user_preferences.view_type_items);
    const originalData = ref([]);
    const bls = ref([]);
    const bookings = ref([]);
    const ot_types = ref([]);
    const mf_ids = ref([]);
    const ot_status = ref([]);
    const created_date = ref([]);
    const otSelected = ref("");
    const form = ref(false);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: parseInt(user.value.user_preferences.items_per_page),
      total: totalFiles.value,
      last_page: Math.floor(
        totalFiles.value / user.value.user_preferences.items_per_page
      ),
    });

    const header = ref([
      { column: "status_led", key: "status_led" },
      { column: t("ioffice"), key: "ioffice", type: "link", size: "small" },
      {
        column: t("ifileNumber"),
        key: "ifileNumber",
        type: "link",
        size: "small",
      },
      {
        column: t("ibookingNumber"),
        key: "booking",
        type: "link",
        size: "small",
      },
      { column: t("iblNumber"), key: "bl", type: "link", size: "small" },
      { column: t("iTransportOrderType"), key: "type" },
      { column: t("iDescription"), key: "description" },
      { column: t("accouting_date"), key: "accouting_date", size: "small" },
      { column: t("itransport_type"), key: "transportation_type" },
      { column: t("doc_status"), key: "edi_path_status" },
      { column: t("ot_shipping_date"), key: "ot_shipping_date", size: "small" },
    ]);

    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
    ]);

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const createFieldItems = (data) => {
      return data.map(function (element) {
        element.items = {
          ioffice: element.booking
            ? element.booking.maritime_voyage.maritime_file.office.name
            : "",
          ifileNumber: element.booking
            ? element.booking.maritime_voyage.maritime_file.code
            : "",
          ibookingNumber: element.booking
            ? element.booking.booking_number
            : " - ",
          iblNumber: element.bills_of_lading
            ? element.bills_of_lading.bl_number
            : " - ",
          iTransportOrderType: element.type.name,
          ot_shipping_date:
            element.edi_paths.length > 0 &&
            element.edi_paths[element.edi_paths.length - 1].status === 3
              ? formatDate(
                  element.edi_paths[element.edi_paths.length - 1].created_at
                )
              : "-",
          itransport_type:
            element.transportation_type == "P"
              ? "Propio"
              : element.transportation_type == "C"
              ? "Cedido"
              : element.transportation_type,
          /*admission_status:
            element.admission_status == "F"
              ? t("iis_full")
              : element.admission_status == "E"
              ? t("iis_empty")
              : element.admission_status,
          iReceptionStatus:
            element.reception_status == "F"
              ? t("iis_full")
              : element.reception_status == "E"
              ? t("iis_empty")
              : element.reception_status,*/
          iDescription:
            element.description.length > 25
              ? element.description.substr(0, 25) + "  ..."
              : element.description,
        };
        return element;
      });
    };

    const getItems = async () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/transportation_orders`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          orderByCreatedAt: 1,
          bls: bls.value,
          bookings: bookings.value,
          min_date:
            created_date.value != [] && created_date.value != null
              ? created_date.value[0]
              : "",
          max_date:
            created_date.value != [] && created_date.value != null
              ? created_date.value[1]
              : "",
          type_ids: ot_types.value,
          status_ids: ot_status.value,
          mf_ids: mf_ids.value,
        },
      }).then(({ data }) => {
        originalData.value = data.transportation_orders;
        dataList.value = createFieldItems(data.transportation_orders);
        pagination.value.total = data.pagination.total;
        pagination.value.last_page = data.pagination.last_page;
        filtered.value =
          viewType.value === "card"
            ? data.transportation_orders
            : createTableItems(data.transportation_orders);
        // onSearch();
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/transport_orders", label: "transport_orders" },
      ]);
      getItems();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      getItems();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      getItems();
    };

    const onSearch = () => {
      let data = createFieldItems(dataList.value);
      if (viewType.value == "table") {
        data = createTableItems(dataList.value);
      }
      filtered.value = data.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
      store.commit("setLoadingStatus", false);
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          status_led:
            element.edi_paths.length === 0 ? "none" : getColorTable(element),
          ioffice: element.booking
            ? element.booking.maritime_voyage.maritime_file.office.name
            : "",
          ifileNumber: element.booking
            ? element.booking.maritime_voyage.maritime_file.code
            : "",
          bl: element.bills_of_lading
            ? element.bills_of_lading.bl_number
            : " - ",
          booking: element.booking ? element.booking.booking_number : " - ",
          transportation_type:
            element.transportation_type == "P"
              ? "Propio"
              : element.transportation_type == "C"
              ? "Cedido"
              : element.transportation_type,
          type: element.type.name,
          edi_path_status:
            element.edi_paths.length > 0
              ? element.edi_paths[element.edi_paths.length - 1]
                  .status_dictionary.outbound
              : t("file_not_generated"),
          ot_shipping_date:
            element.edi_paths.length > 0 &&
            element.edi_paths[element.edi_paths.length - 1].status === 3
              ? formatDate(
                  element.edi_paths[element.edi_paths.length - 1].created_at
                )
              : "-",
          description:
            element.description.length > 25
              ? element.description.substr(0, 20) + "  ..."
              : element.description,
          accouting_date: formatDate(element.created_at),
        });
      });
      return result;
    };

    const seeDetails = (ot) => {
      const tab = ot["bills_of_lading"] !== null ? "billOfLading" : "bookings";
      const item_id =
        ot["bills_of_lading"] !== null ? ot["bl_id"] : ot["booking_id"];
      const mf_id = ot["booking"]["maritime_voyage"]["maritime_file_id"];
      store.commit("setBlBoTab", {
        tab: ot["bills_of_lading"] !== null ? "three" : "second",
        item_id: ot["id"],
      });
      store.commit("setMaritimeFileTab", {
        tab: tab,
        item_id: item_id,
      });
      if (ot["bills_of_lading"] === null) {
        localStorage.setItem("booking_id", item_id);
      } else {
        localStorage.setItem("bl_id", item_id);
      }
      router.push("/maritimefile/" + mf_id);
    };

    const reload = () => {
      getItems();
      form.value = false;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        seeDetails(dataList.value[param3]);
      }
    };

    const getBackgroundClass = (ot) => {
      switch (ot.edi_paths[ot.edi_paths.length - 1].status) {
        case 1:
          return "bg-primary";
        case 2:
          return "bg-warning";
        case 3:
          return "bg-success";
        case 4:
          return "bg-danger";
        case 5:
          return "bg-danger";
        case 6:
          return "bg-danger";
        case 7:
          return "bg-danger";
        default:
          return "bg-secondary text-black";
      }
    };

    const getColorTable = (ot) => {
      switch (ot.edi_paths[ot.edi_paths.length - 1].status) {
        case 1:
          return "info";
        case 2:
          return "warning";
        case 3:
          return "success";
        case 4:
          return "danger";
        case 5:
          return "danger";
        case 6:
          return "danger";
        case 7:
          return "danger";
        default:
          return "none";
      }
    };

    const onLink = (index, row, event, is_card = false) => {
      let tab = "";
      let item_id = "";
      let mf_id = "";

      if (event === "ioffice" || event === t("ioffice")) {
        if (is_card) {
          router.push(
            "/companies/offices/" +
              row["booking"]["maritime_voyage"]["maritime_file"]["office_id"]
          );
        } else {
          router.push(
            "/companies/offices/" +
              originalData.value[index]["booking"]["maritime_voyage"][
                "maritime_file"
              ]["office_id"]
          );
        }
      } else {
        if (is_card) {
          tab =
            event === "iblNumber"
              ? "billOfLading"
              : event === "ifileNumber"
              ? "maritime_file"
              : "bookings";
          item_id =
            event === "iblNumber"
              ? row["bl_id"]
              : event === "ifileNumber"
              ? row["booking"]["maritime_voyage"]["maritime_file_id"]
              : row["booking_id"];
          mf_id = row["booking"]["maritime_voyage"]["maritime_file_id"];
        } else {
          tab =
            event === t("iblNumber")
              ? "billOfLading"
              : event === t("ifileNumber")
              ? "maritime_file"
              : "bookings";
          item_id =
            event === t("iblNumber")
              ? originalData.value[index]["bl_id"]
              : event === t("ifileNumber")
              ? originalData.value[index]["booking"]["maritime_voyage"][
                  "maritime_file_id"
                ]
              : originalData.value[index]["booking_id"];
          mf_id =
            originalData.value[index]["booking"]["maritime_voyage"][
              "maritime_file_id"
            ];
        }

        if (tab !== "maritime_file") {
          store.commit("setMaritimeFileTab", {
            tab: tab,
            item_id: item_id,
            from: tab === "bookings" ? "bookins_view" : "",
          });
        }
        if (tab === "bookings") {
          localStorage.setItem("booking_id", item_id);
        }
        if (tab === "billOfLading") {
          localStorage.setItem("bl_id", item_id);
        }
        router.push("/maritimefile/" + mf_id);
      }
    };

    return {
      canCreate,
      canSeeDetails,
      canEdit,
      canDelete,
      created_date,
      bls,
      bookings,
      filtered,
      dataList,
      search,
      viewType,
      header,
      tableButtons,
      pagination,
      form,
      otSelected,
      ot_types,
      mf_ids,
      ot_status,
      formatDate,
      onSearch,
      getItems,
      currentPageChange,
      setItemsPerPage,
      seeDetails,
      reload,
      actionsButton,
      getBackgroundClass,
      getColorTable,
      onLink,
      user,
    };
  },
});
